import React from "react"
import { twMerge } from "tailwind-merge"
import { VariantProps, tv } from "tailwind-variants"

interface ButtonProps extends Omit<buttonVariantsTypes, "disabled" | "fullWidth"> {
  type?: ButtonType
  onClick?: () => void
  children: React.ReactNode
  className?: string
  disabled?: boolean
  fullWidth?: boolean
}

type ButtonType = React.ButtonHTMLAttributes<HTMLButtonElement>["type"]

type buttonVariantsTypes = VariantProps<typeof buttonVariants>

const buttonVariants = tv({
  slots: {
    base: "relative text-center font-pally text-[14px] font-medium uppercase leading-6 text-primary-50 h-fit",
    firstLayer: "absolute left-0 top-0 z-10 h-full w-full rounded-2xl bg-gradient-to-t from-0% to-100%",
    secondLayer: "absolute -bottom-1 left-0 h-full w-full rounded-2xl",
    thirdLayer: "absolute left-0 top-[1px] h-full w-full rounded-2xl bg-[#FFFFFF52]",
    contentWrapper: "relative z-20 flex justify-center items-center",
  },
  variants: {
    color: {
      primary: {
        firstLayer: "from-[#932495] to-[#FC6FFE]",
        secondLayer: "bg-[#751c76]",
      },
      secondary: {
        firstLayer: "bg-primary-100",
        secondLayer: "bg-primary-200",
        base: "text-primary-400",
      },
      white: {
        firstLayer: "from-[#EEEEEE] to-primary-50 from-100%",
        secondLayer: "bg-primary-200",
      },
      blue: {
        firstLayer: "from-[#0866FC] to-[#64A1FF]",
        secondLayer: "bg-[#003BA9]",
      },
      gold: {
        firstLayer: "from-[#D68427] to-[#EFC054]",
        secondLayer: "bg-[#B87320]",
      },
      red: {
        firstLayer: "bg-error-400",
        secondLayer: "bg-error-600",
      },
      gray: {
        firstLayer: "from-primary-400 to-primary-300 from-100%",
        secondLayer: "bg-[#2B2C36]",
      },
      green: {
        firstLayer: "from-[#015F4F] to-[#0ECFAE]",
        secondLayer: "bg-[#022C25]",
      },
    },
    disabled: {
      base: "after:absolute after:rounded-2xl after:left-0 after:top-0 after:h-[calc(100%+5.5px)] after:w-full after:bg-black/55 after:content-['_'] after:z-20",
    },
    size: {
      md: {
        base: "px-3 py-2.5",
      },
      sm: {
        base: "px-3 py-1.5",
      },
    },
    fullWidth: {
      base: "w-full",
    },
  },
})

const Button = ({
  children,
  className,
  color = "primary",
  disabled = false,
  onClick,
  type = "button",
  fullWidth = false,
  size = "md",
}: ButtonProps) => {
  const isButtonDisabled: buttonVariantsTypes["disabled"] = disabled === false ? undefined : "base"
  const isButtonFullWidth: buttonVariantsTypes["fullWidth"] = fullWidth === false ? undefined : "base"
  const { base, firstLayer, secondLayer, thirdLayer, contentWrapper } = buttonVariants({
    color,
    disabled: isButtonDisabled,
    fullWidth: isButtonFullWidth,
    size,
  })
  return (
    <button disabled={disabled} type={type} className={twMerge(base(), className)} onClick={onClick}>
      <span className={firstLayer()} />
      <span className={secondLayer()} />
      <span className={thirdLayer()} />
      <div className={contentWrapper()}>{children}</div>
    </button>
  )
}

export default Button
